.footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 4px;
}