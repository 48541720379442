.container {
	display: flex;
	flex-direction: column;
    align-self: flex-start;
    justify-self: flex-start;
    margin-bottom: 60px;
	margin-top: 10px;
}

.content {
    padding-left: 24px;
    font-size: 14px;
    margin-bottom: 24px;
}

.paymentMethods {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 10px;
}

.paymentMethodsContainer {
    margin-top: 16px;
}

.customPaymentMethod {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
}

.footer {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 24px 24px 24px 96px;
	background: white;
	z-index: 9;
}